(function ($) {
	jQuery(document).on("click", "a", function (e) {
		const href = $(this).attr("href");
		if (/^#/.test(href)) {
			e.preventDefault();
			const id = jQuery(this).attr("href");
			const $target = jQuery(document).find(id);
			const headerH = jQuery("#thhf-masthead").outerHeight();

			console.log("id", id);

			if ($target.length) {
				setTimeout(() => {
					jQuery([document.documentElement, document.body]).animate(
						{
							scrollTop: $target.offset().top - headerH - 10,
						},
						500,
					);
				}, 0);
			}
		}
	});

	function buildModal(title = "") {
		return `<div class="modal loading">
		<div class="modal__head">
			<h3 class="modal__title"></h3>
			<div class="modal__close">
				<svg version="1.1" x="0px" y="0px" viewBox="0 0 1200 1200" style="enable-background:new 0 0 1200 1200;" xml:space="preserve">
					<path fill="currentColor" d="M861.1,407.7c9.7-9.4,14.5-20.9,14.4-34.4c0.1-13.5-4.7-25.1-14.4-34.8c-9.4-9.4-20.9-14.2-34.4-14.4
		c-13.5,0.1-25.1,4.9-34.8,14.4L600,530.9L407.7,338.5c-9.4-9.4-20.9-14.2-34.4-14.4c-13.5,0.1-25.1,4.9-34.8,14.4
		c-9.4,9.7-14.2,21.3-14.4,34.8c0.1,13.5,4.9,25,14.4,34.4L530.9,600L338.5,792c-9.4,9.7-14.2,21.3-14.4,34.8
		c0.1,13.5,4.9,25,14.4,34.4c9.7,9.7,21.3,14.5,34.8,14.4c13.5,0.1,25-4.7,34.4-14.4l192.3-192l192,192c9.7,9.7,21.3,14.5,34.8,14.4
		c13.5,0.1,25-4.7,34.4-14.4c9.7-9.4,14.5-20.9,14.4-34.4c0.1-13.5-4.7-25.1-14.4-34.8l-192-192L861.1,407.7z" />
				</svg>
			</div>
		</div>
		<div class="modal__content"></div>
	</div>`;
	}

	$(document).on("click", ".gallery__item", function () {
		const galleryId = $(this).attr("data-id");
		const galleryTitle = $(this).attr("data-title");
		let modal = $(buildModal(galleryTitle));

		$("body").append(modal);
		$("body").addClass("overflow-hidden");
		$(modal).addClass("open");

		modal.find(".modal__close").on("click", function () {
			$("body").removeClass("overflow-hidden");
			modal.removeClass("open");
			setTimeout(() => {
				modal.remove();
			}, 400);
		});

		$.ajax({
			url: jsData.ajaxUrl,
			dataType: "json",
			data: {
				action: "get_gallery",
				gallery_id: galleryId,
			},
			success: function (res) {
				const content = $(res.html);
				const slider = content.find(".sub-gallery__slider");
				const nav = content.find(".sub-gallery__nav");
				const sliderId = slider.attr("id");
				const navId = nav.attr("id");

				slider.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					fade: true,
					asNavFor: `#${navId}`,
				});
				nav.slick({
					slidesToShow: 3,
					slidesToScroll: 1,
					asNavFor: `#${sliderId}`,
					centerMode: true,
					focusOnSelect: true,
					arrows: false,
					vertical: true,
					verticalSwiping: true,
					autoplay: true,
					autoplaySpeed: 3000,
				});
				modal.removeClass("loading");
				modal.find(".modal__content").append(content);
			},
		});
	});

	$(".gallery").each(function () {
		const gallery = $(this);
		const galleryInner = gallery.find(".gallery__inner");

		galleryInner.isotope({
			itemSelector: ".gallery__item",
		});

		$(window).on("scroll", function () {
			const loadMore = $(gallery).find(".gallery__load-more");
			console.log("loadMore.length", loadMore.length);
			if (!loadMore.length) {
				return;
			}

			const page = $(loadMore).attr("data-page") || 0;
			const top = $(window).scrollTop();
			const offset = loadMore.offset();
			const isBottom =
				$(window).scrollTop() + $(window).height() ==
				$(document).height();
			const allowFetch =
				(top > offset.top || isBottom) && !loadMore.hasClass("loading");

			if (allowFetch) {
				loadMore.addClass("loading");
				const nextPage = parseInt(page) + 1;
				$.ajax({
					url: jsData.ajaxUrl,
					dataType: "json",
					data: {
						action: "get_gallery_items",
						page: nextPage,
					},
					success: function (res) {
						if (res?.html) {
							const newItems = $(res.html);
							galleryInner.append(newItems);
							galleryInner
								.isotope()
								.append(newItems)
								.isotope("appended", newItems)
								.isotope("layout");
							loadMore.attr("data-page", nextPage);
						} else {
							loadMore.remove();
						}
						loadMore.removeClass("loading");
					},
				});
			}
		});
	});

	$(".sticky-kit").stick_in_parent({
		offset_top: $("#thhf-masthead").outerHeight(),
	});
})(jQuery);
